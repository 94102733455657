import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID, // Replace with your client ID from the Azure portal
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`, // Replace with your tenant ID
    redirectUri:
      process.env.NODE_ENV === "production"
        ? "https://chat.imcpaa.com/"
        : window.location.origin,
    postLogoutRedirectUri: "https://imcpaa.com",
  },
};

// console.log(msalConfig);
export const pca = await PublicClientApplication.createPublicClientApplication(
  msalConfig
);

export const login = async () => {
  try {
    const loginRequest = {
      scopes: ["openid", "profile", "User.Read"],
    };
    pca.clearCache();

    await pca.loginRedirect(loginRequest);

    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getADUser = async () => {
  const account = pca.getAllAccounts()[0];
  if (account) {
    pca.setActiveAccount(account);
    const accessToken = await getAccessToken();
    const userInfo = await getAccountDetails(accessToken);
    // console.log(userInfo);
    return userInfo;
  }
  return null;
};

const getAccessToken = async () => {
  const tokenRequest = {
    scopes: ["User.Read"],
  };
  try {
    const response = await pca.acquireTokenSilent(tokenRequest);
    return response.accessToken;
  } catch (error) {
    console.error(
      "Failed to acquire token silently. Trying popup method.",
      error
    );
    try {
      const response = await pca.acquireTokenRedirect(tokenRequest);
      return response.accessToken;
    } catch (err) {
      console.error("Failed to acquire token using popup method.", err);
    }
  }
  return null;
};

const getAccountDetails = async (accessToken) => {
  const endpoint = "https://graph.microsoft.com/v1.0/me";
  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    const data = await response.json();
    // console.log("Data From Graph API:", data);
    return {
      givenName: data.givenName,
      surname: data.surname,
      upn: data.userPrincipalName,
    };
  } else {
    console.error("Failed to fetch user details from Microsoft Graph");
    return null;
  }
};
