import React, { useState, useRef, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  // sendMessageToServer,
  // sendMessagesToServer,
  fetchSearchTopicsFromServer,
  fetchChatHistoryFromServer,
  fetchHistoryFromServer,
  deleteHistoryFromServer,
  OPENAI_QUERY_STREAM_URL,
} from "../backend/apiService";
import { getADUser } from "../backend/AuthProvider";

import AppHeader from "../components/AppHeader";
import ChatBox from "../components/ChatBox";
import MessageInput from "../components/MessageInput";
import HistoryItem from "../components/HistoryItem";
import SearchIndexItem from "../components/SearchIndexItem";
import "./Chatbot.css";

function Chatbot() {
  const leftDrawerRef = useRef(null);
  const rightDrawerRef = useRef(null);

  const [searchTopic, setSearchTopic] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [user, setUser] = useState({}); // manage user profile
  const [chatId, setChatId] = useState(uuidv4()); // handle new session chatId
  const [isNewChat, setIsNewChat] = useState(true); // to be use to mark for first conversation as chat history

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const replaceLastMessage = (newMessage) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      updatedMessages[updatedMessages.length - 1] = newMessage;
      return updatedMessages;
    });
  };

  // handle streaming messages
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputValue.trim() !== "") {
      // Create newMessages array to include last user message and reset input value
      const newMessages = [...messages, { role: "user", content: inputValue }];
      setMessages(newMessages);
      setInputValue("");
      const body = {
        origin: user.upn,
        chatId: chatId,
        messages: newMessages,
        searchIndex: selectedTopic ? selectedTopic.searchIndex : "none",
        isNewChat: isNewChat,
      };

      // Set reply to Waiting and to be replaced with animated ...
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: "Thinking" },
      ]);
      setIsLoading(true);

      let data = "";
      setIsNewChat(false);
      fetch(OPENAI_QUERY_STREAM_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.body || typeof response.body.getReader !== "function") {
            throw new Error("Streaming not supported in this browser.");
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");

          function readStream() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                setIsLoading(false);
                return;
              }

              // Process each chunk
              data += decoder.decode(value);
              // console.log(data);

              // Add bot response to messages
              replaceLastMessage({ role: "assistant", content: data });
              // Recursively call readStream() until done is true
              return readStream();
            });
          }

          return readStream();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const clearMessages = () => {
    setMessages([]); // Clears the messages array
    setChatId(uuidv4());
    setIsNewChat(true);
  };

  const togglesearchTopicDrawer = () => {
    if (drawerOpen) {
      // If the right drawer is open, close it
      setDrawerOpen(false);
    }
    setLeftDrawerOpen((prev) => !prev);
  };

  const toggleHistoryDrawer = () => {
    if (leftDrawerOpen) {
      // If the left drawer is open, close it
      setLeftDrawerOpen(false);
    }
    setDrawerOpen((prev) => !prev);
  };

  const handleSearchTopicSelect = (topic) => {
    setSelectedTopic(topic);
    setLeftDrawerOpen(false); // Close the left drawer when a topic is selected
  };

  const deleteHistoryHandler = async (history, event) => {
    event.stopPropagation();
    // console.log("deleting ", history.chatId);
    if (history) {
      setIsLoading(true);
      try {
        await deleteHistoryFromServer(history.chatId);
        // loadchatHistory();
        setChatHistory((prev) =>
          prev.filter((item) => item.chatId !== history.chatId)
        );
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleHistorySelect = async (history) => {
    // console.log("Selected history", history.chatId);
    setDrawerOpen(false); // Close the left drawer when a topic is selected
    // console.log("handleHistorySelect", selectedHistory);

    // console.log("Current ChatId ", chatId);
    // console.log("Selected ChatId", history.chatId);
    // Check if the newly selected is same as previously selected.
    if (history && history.chatId !== selectedHistory?.chatId) {
      setIsLoading(true);
      try {
        // console.log("Loading ChatId", history.chatId);
        const chatHistory = await fetchHistoryFromServer(history.chatId);
        // console.log(chatHistory);
        setMessages([]);
        // eslint-disable-next-line
        chatHistory.map((chat, index) => {
          setMessages((prev) => [
            ...prev,
            { role: chat.role, content: chat.message },
          ]);
        });
        setSelectedHistory(history);
        setChatId(history.chatId);
        setIsNewChat(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  //Login user if not authenicated
  useEffect(() => {
    const getUser = async () => {
      const userInfo = await getADUser();
      setUser(userInfo);
    };

    getUser();
  }, []);

  // Fetch Search Index (Topic like General GPT)
  useEffect(() => {
    const loadsearchTopic = async () => {
      try {
        const searchTopicList = await fetchSearchTopicsFromServer(user.upn);
        // console.log(searchTopicList);
        setSearchTopic(searchTopicList);
        if (searchTopicList.length > 0) {
          setSelectedTopic(searchTopicList[0]);
        }
      } catch (error) {
        console.error("Error fetching searchTopic:", error);
        // Optionally, handle the error in your UI, e.g., show an error message to the user
      }
    };

    if (user.upn) {
      loadsearchTopic();
    }
  }, [user]);

  // Fetch Search History Header
  const loadchatHistory = useCallback(async () => {
    try {
      const searchTopicList = await fetchChatHistoryFromServer(user.upn);
      // console.log(searchTopicList);
      setChatHistory(searchTopicList);
    } catch (error) {
      console.error("Error fetching searchTopic:", error);
      // Optionally, handle the error in your UI, e.g., show an error message to the user
    }
  }, [user.upn]);

  useEffect(() => {
    // console.log("Loading history");

    loadchatHistory();
  }, [user, isNewChat, loadchatHistory]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click was outside both the left and right drawers
      if (
        leftDrawerRef.current &&
        !leftDrawerRef.current.contains(event.target) &&
        rightDrawerRef.current &&
        !rightDrawerRef.current.contains(event.target)
      ) {
        setLeftDrawerOpen(false);
        setDrawerOpen(false);
      }
    };

    // Attach the click listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={"App"}>
      <AppHeader
        selectedTopic={selectedTopic ? selectedTopic.searchTopic : ""}
        isLoading={isLoading}
        logoVisible={messages.length}
      />
      {chatHistory.length > 0 ? (
        <>
          <button
            className={`historyIcon ${drawerOpen ? "iconMoved" : ""}`}
            onClick={toggleHistoryDrawer}
          >
            ≡
          </button>
          <div
            ref={rightDrawerRef}
            className={`historyDrawer ${drawerOpen ? "drawerOpen" : ""}`}
          >
            <ul>
              {chatHistory.map((history, index) => (
                <li
                  key={index}
                  onClick={() => handleHistorySelect(history)}
                  className={selectedHistory === history ? "selected" : ""}
                >
                  {history ? (
                    <HistoryItem
                      title={history.message}
                      isoDate={history.entry_date_time}
                      deleteHandler={
                        selectedHistory?.chatId !== history.chatId
                          ? (event) => deleteHistoryHandler(history, event)
                          : null
                      }
                    />
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}

      <button
        className={`searchTopicsIcon ${leftDrawerOpen ? "drawerOpen" : ""}`}
        onClick={togglesearchTopicDrawer}
      >
        <i className="fa-solid fa-code"></i>
      </button>
      <div
        ref={leftDrawerRef}
        className={`searchTopicsDrawer ${leftDrawerOpen ? "drawerOpen" : ""}`}
      >
        <div className="selectATopic">Select a Topic</div>
        <ul>
          {searchTopic.map((topic, index) => (
            <li
              key={index}
              onClick={() => handleSearchTopicSelect(topic)}
              // className={selectedTopic === topic ? "selected" : ""}
            >
              {topic ? (
                <SearchIndexItem
                  title={topic.searchTopic}
                  description={topic.description}
                  selected={selectedTopic === topic}
                />
              ) : null}
            </li>
          ))}
        </ul>
      </div>

      <ChatBox messages={messages} isLoading={isLoading} user={user} />

      <MessageInput
        clearMessages={clearMessages}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        state={isLoading}
      />
    </div>
  );
}

export default Chatbot;
