import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import "./Message.css";

function Message({ text, sender, isLoading, ref }) {
  const [copied, setCopied] = useState(false);

  const processText = (text, sender) => {
    if (sender === "user") return text;
    // remove [doc] ref and handle bold text
    const message = text.replace(
      /\[doc\d+\]|\{endOfTokens\}|\{endOf\}|\{endOfTokens/g,
      ""
    );
    let parts = message.split("**");
    let jsxText = parts.map((part, index) =>
      index % 2 === 0 ? (
        <span key={index}>{part}</span>
      ) : (
        <strong key={index}>{part}</strong>
      )
    );

    return (
      <>
        <div>{jsxText}</div>
      </>
    );
  };

  const copyToClipboardHandler = (jsx) => {
    // Strip the HTML tags using a regular expression
    // Convert the JSX to a string
    const jsxString = ReactDOMServer.renderToStaticMarkup(jsx);
    const strippedContent = jsxString.replace(/<\/?[^>]+(>|$)/g, "");

    const decodeHtmlEntities = (text) => {
      const textArea = document.createElement("textarea");
      textArea.innerHTML = text;
      return textArea.value;
    };

    // Copy to clipboard
    navigator.clipboard.writeText(decodeHtmlEntities(strippedContent));

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const jsxText = processText(text, sender);

  return (
    <div className={`message-container ${sender}`}>
      <div className={`message ${sender}`}>
        {isLoading && text === "Thinking" ? (
          <div className="loading-indicator"></div>
        ) : (
          <>
            {sender === "assistant" ? (
              <>
                <div className="message warning">
                  AI-generated content may be incorrect.
                </div>
                <div
                  className="copy-icon"
                  onClick={() => copyToClipboardHandler(jsxText)}
                >
                  {!isLoading ? (
                    copied ? (
                      <i class="fa-solid fa-check"></i>
                    ) : (
                      <i className="fa-regular fa-copy"></i>
                    )
                  ) : null}
                </div>
              </>
            ) : null}
            <span className="word">{jsxText}</span>
          </>
        )}
      </div>
    </div>
  );
}

export default Message;
