import React from "react";
import "./HistoryItem.css";

import moment from "moment";

function HistoryItem({ title, isoDate, deleteHandler }) {
  const localDate = isoDate ? moment(isoDate).format("LL LTS") : null;
  return (
    <div className="rounded-box">
      <h1 className="title">{title}</h1>
      <div className="subtitle-container">
        <p className="subtitle">{localDate}</p>
        {deleteHandler ? (
          <button className="delete-icon" onClick={deleteHandler}>
            <i className="fa-regular fa-trash-can"></i>
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default HistoryItem;
