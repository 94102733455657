// apiService.js

const API_BASE_URL = "https://imcgpt-api.azurewebsites.net";
// const API_BASE_URL = "http://localhost:5001";

// Use for testing only
export const sendMessageToServer = async (message) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/chat-response`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message: message }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("There was an error sending the message:", error);
    throw error;
  }
};

export const OPENAI_QUERY_STREAM_URL = `${API_BASE_URL}/api/chat-gpt-stream`;

// Not use anymore.. converted to stream in main App
export const sendMessagesToServer = async (
  origin,
  chatId,
  isNewChat,
  messages,
  searchIndex
) => {
  const messagesData = {
    origin: origin,
    chatId: chatId,
    messages: messages,
    searchIndex: searchIndex,
    isNewChat: isNewChat,
  };

  try {
    const response = await fetch(`${API_BASE_URL}/api/chat-gpt`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(messagesData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("There was an error sending the message:", error);
    throw error;
  }
};

// Upn would be used to determine by backend on what are the topics available
export const fetchSearchTopicsFromServer = async (userUpn) => {
  const requestBody = {
    userUpn: userUpn,
  };

  try {
    const response = await fetch(`${API_BASE_URL}/api/topics`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }); // Assuming '/topics' is the endpoint to get the topics
    const data = await response.json();
    // console.log("Api topic list", data);
    return data;
  } catch (error) {
    console.error("Error fetching topics from server:", error);
    throw error; // Re-throw the error so it can be caught and handled in the component
  }
};

// Fetch historical converation header with UserUpn
export const fetchChatHistoryFromServer = async (userUpn) => {
  const requestBody = {
    userUpn: userUpn,
  };

  try {
    const response = await fetch(`${API_BASE_URL}/api/chatTopics`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }); // Assuming '/topics' is the endpoint to get the topics
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching chat history topic from server:", error);
    throw error; // Re-throw the error so it can be caught and handled in the component
  }
};

// Fetch conversation based on chatId
export const fetchHistoryFromServer = async (chatId) => {
  const requestBody = {
    chatId: chatId,
  };

  try {
    const response = await fetch(`${API_BASE_URL}/api/chatHistory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching chat history from server:", error);
    throw error; // Re-throw the error so it can be caught and handled in the component
  }
};

export const deleteHistoryFromServer = async (chatId) => {
  const requestBody = {
    chatId: chatId,
  };

  try {
    const response = await fetch(`${API_BASE_URL}/api/deleteChatHistory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching chat history from server:", error);
    throw error; // Re-throw the error so it can be caught and handled in the component
  }
};
