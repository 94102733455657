import React, { useRef, useEffect } from "react";
import Message from "./Message";
import { isMobileDevice } from "../backend/utilities";
import "./ChatBox.css";

function ChatBox({ messages, isLoading, user }) {
  const chatEndRef = useRef(null); // used to scroll to the end

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="chatWrapper">
      <div className="chatContainer">
        <div className={isMobileDevice() ? "chatbox-mobile" : "chatbox"}>
          {messages.length > 0 ? (
            <>
              {messages.map((message, index) => (
                <Message
                  key={index}
                  text={message.content}
                  sender={message.role}
                  isLoading={isLoading && index === messages.length - 1}
                />
              ))}
              <div className="spacer" ref={chatEndRef}></div>
            </>
          ) : (
            <div className="introduction">
              <img
                src="/imc-logo.png"
                // src="/imc-logo-big-300-63.png"
                alt="IMC"
                className="title-logo"
              />
              <p>Hello {user.givenName}, </p>
              <p>
                Welcome to our inhouse AI Chatbot. You can select the{" "}
                <strong>
                  <i className="fa-solid fa-code"></i> topic
                </strong>{" "}
                on the top left corner button or <strong>≡ continue </strong>
                your previous conversation by selecting it from the top right
                corner button.
              </p>
              <p>
                Usually, for the best results, it's a good idea to{" "}
                <strong>
                  <i className="fas fa-broom"></i> clear
                </strong>{" "}
                the current conversation when starting a new topic. This helps
                especially if the new questions aren't related to previous ones.
                😊
              </p>
              <p>
                Enjoy! Do provide your feedback to{" "}
                <i className="fa-regular fa-envelope"></i>{" "}
                <a href="mailto:ai@imcpaa.com">ai@imcpaa.com</a>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatBox;
