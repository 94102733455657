import React from "react";
import "./App.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import Chatbot from "./screens/Chatbot";
import Splash from "./screens/Splash";

function App() {
  return (
    <div>
      <AuthenticatedTemplate>
        <Chatbot />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Splash />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;
