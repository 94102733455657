import React, { useEffect, useState } from "react";
import "./Splash.css";

import { useMsal } from "@azure/msal-react";

function Splash() {
  const { instance } = useMsal();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const signIn = async () => {
      // const loginRequest = {
      //   scopes: ["openid", "profile", "User.Read"],
      // };
      console.log(instance);
      await instance.loginRedirect();
    };
    signIn();
  }, [instance]);

  return (
    <div className="splash-container">
      <img src="/imc-logo.png" alt="IMC" className="company-logo" />
      <div
        className="bounce-loader"
        onClick={() => setDisplay((prev) => !prev)}
      >
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div></div>
      </div>
      {display ? (
        <div>
          <p>MSAL_CLIENT_ID={process.env.REACT_APP_MSAL_CLIENT_ID}</p>
          <p>MSAL_TENANT_ID={process.env.REACT_APP_MSAL_TENANT_ID}</p>
        </div>
      ) : null}
    </div>
  );
}

export default Splash;
