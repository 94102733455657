import React from "react";
import "./SearchIndexItem.css";

function SearchIndexItem({ title, description, selected }) {
  return (
    <div className={`drawer-item ${selected ? "selected" : null}`}>
      <p className="item-title">{title}</p>
      <p className="item-description">{description}</p>
    </div>
  );
}

export default SearchIndexItem;
