import React, { useState } from "react";
import "./AppHeader.css";

import { useMsal } from "@azure/msal-react";

function AppHeader({ selectedTopic, isLoading, logoVisible = true }) {
  const [clickCount, setClickCount] = useState(0);
  const { instance } = useMsal();

  const handleLogoClick = () => {
    setClickCount((prev) => prev + 1);

    console.log("Click:", clickCount);
    if (clickCount > 3) {
      instance.logoutRedirect();
    }
  };

  return (
    <div className="app-header">
      <div className={isLoading ? "logo-bounce" : ""}>
        {logoVisible ? (
          <img
            src="/IMC-logo-white.png"
            // src="/imc-logo-big-300-63.png"
            alt="IMC"
            className="app-logo"
            onClick={handleLogoClick}
          />
        ) : (
          <div className="app-logo"></div>
        )}
      </div>
      {selectedTopic && (
        <div className="selectedTopicDisplay">{selectedTopic}</div>
      )}
    </div>
  );
}

export default AppHeader;
