import React, { useEffect, useRef } from "react";

import { isMobileDevice } from "../backend/utilities";
import "./MessageInput.css";

function MessageInput({
  clearMessages,
  inputValue,
  handleInputChange,
  handleSubmit,
  state,
}) {
  const inputBoxRef = useRef(null);
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (!state && inputBoxRef.current) {
      inputBoxRef.current.focus();
    }
  }, [state]);

  return (
    <div className="message-input">
      <button className="clear-button" onClick={clearMessages} disabled={state}>
        <i className="fas fa-broom"></i>
      </button>
      <div className="text-area">
        <div className="text-private">
          <div>
            <i className="fa-solid fa-shield"></i>
            <> </>
            Your personal and company data are protected in this chat
          </div>
          {/* <button className="hints-button">Hints!</button> */}
        </div>
        <textarea
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className="input-text"
          placeholder="Type your message..."
          rows={isMobileDevice() ? 2 : 6}
          style={{ overflowY: "auto" }}
          disabled={state}
          ref={inputBoxRef}
        />
      </div>
      <button className="send-button" onClick={handleSubmit} disabled={state}>
        <i className="fa-solid fa-paper-plane"></i>
      </button>
    </div>
  );
}

export default MessageInput;
